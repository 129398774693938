import './Admin.scss'
import React from 'react'
import SEO from "../../components/seo";
import AdminLayout from "../../components/AdminLayout";

const AdminPage = () => (
    <AdminLayout>
        <SEO title="Admin"/>
        Admin Page
    </AdminLayout>
);

export default AdminPage
