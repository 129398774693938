import './AdminLayout.scss'
import React from 'react'

const AdminLayout = ({children}) => (
    <>
        <main>{children}</main>
    </>
);

export default AdminLayout
